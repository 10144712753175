import { Selection, select, timeDay, timeFormat, timeMonth } from "d3";
import "../../../styles/pages/Agenda.scss";
import { ETipoTarea, IDatumActivity, ITaskAgenda, _AddTaskAgenda, _LoadActivitiesInfo, _RemoveTaskAgenda, _UpdateTaskAgenda } from "../../data/services/Agendas";
import { MOUNTH_NAMES, WEEKDAY } from "../../data/utils/General";
import { BaseUI } from "../bases/BaseUI";
import { ToImageSvgElement } from "../utils/ImageSVG";
import ic_back from '/icons/ic_back.svg?raw';
import ic_next from '/icons/ic_next.svg?raw';
import ic_prev from '/icons/ic_prev.svg?raw';
import ic_trash from '/icons/ic_trash.svg?raw';

import { ICalendarEvent } from "../../data/models/Entities";
import { _LoadCalendarEvent } from "../../data/services/Event";
import { _mapChilds } from "../../data/services/Kids";
import { IWeather, _LoadWeatherInfo } from "../../data/services/Weather";
import { _DateToInputFmt } from "../../utils/General";
import _L, { _HttpMsg } from "../../utils/Labels";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { EmptyTag } from "../components/EmptyTag";
import { Input } from "../components/Input";
import { LOADING } from "../components/Loading";
import { ShowToast } from "../components/Toast";
import { _CreateElementFromHTML } from "../utils/General";
import ic_add from "/icons/ic_add.svg?raw";
import ic_done from "/icons/ic_done.svg?raw";

export class Agenda extends BaseUI {

    private agendaContainer: Selection<HTMLDivElement, any, any, any>;
    private weatherContainer: Selection<HTMLDivElement, any, any, any>;
    private listAllEvents: Selection<HTMLDivElement, any, any, any>;
    private headerList: Selection<HTMLDivElement, any, any, any>;

    private weatherEmptyTag: EmptyTag
    private eventsEmptyTag: EmptyTag

    private indexMounth: number = 0;
    private weatherWeek: IWeather[] | null;
    private listActivities: IDatumActivity[] = [];

    private txtActivity: Input;
    private txtDate: Selection<HTMLInputElement, any, any, any>;
    private btnAddSave: TSelection<"button"> & {
        _SetMode?(mode: "save" | "add"): void;
    };
    private taskSelected: IDatumActivity | null;

    constructor() {
        super({ addMainLogoOptions: true, addOptionsInHeader: true, className: 'ui-agenda' });

        this.agendaContainer = this.bodyContainer.append("div").classed("agenda-container", true);
        this.weatherContainer = this.bodyContainer.append("div").classed("weather-container", true);

        this.CreateListAGendasContainer();
        this.CreateWeatherView();

        this.LoadDataInfo();
        this.LoadActivities();

        this.weatherEmptyTag = new EmptyTag(this.weatherContainer.node())
            ._SetText(_L("agenda.weather_not_available"))
            ._SetColor("var(--color-blue-action)")
    }

    private LoadDataInfo() {
        _LoadWeatherInfo((result) => {
            this.weatherWeek = result;
            this.UpdateWeather();
        });
    }

    private LoadActivities() {
        let [dtStart, dtEnd] = this.GetDateRange();

        const hTitle = this.agendaContainer.select(".header-list").select(".title");
        hTitle.text(MOUNTH_NAMES[dtStart.getMonth()] + " " + dtStart.getFullYear())
        // timeFormat("%B %Y")(dtStart));

        let data: Array<IDatumActivity> = [];

        const OnItemParse = (item: ITaskAgenda) => {
            let id = ETipoTarea.ACT + "_" + item.IdTarea;
            // let dStart = FormatDateToHour(item.Inicio);
            // let dEnd = FormatDateToHour(item.Fin);

            // let range = dStart === dEnd ? "Todo el día" : dStart + " - " + dEnd;
            data.push({
                intId: item.IdTarea,
                id: id,
                range: "", //range,
                start: item.Inicio,
                end: item.Fin,
                title: item.Titulo,
                description: item.Descripcion,
                color: "#FFD23F",
                type: ETipoTarea.ACT,
                icon: "icons/activity/icon-actividad.svg"
            })
        }

        _LoadActivitiesInfo(dtStart, dtEnd, OnItemParse, (_) => {
            const _OnItemParse = (datum: ICalendarEvent) => {
                let id = ETipoTarea.ESPECIAL + "_" + datum.IdEvento;
                // let dStart = FormatDateToHour(datum.Inicio);
                // let dEnd = FormatDateToHour(datum.Fin);

                // let range = dStart === dEnd ? "Todo el día" : dStart + " - " + dEnd;
                data.push({
                    intId: datum.IdEvento,
                    id: id,
                    range: "", // range,
                    start: datum.Inicio,
                    end: datum.Fin,
                    title: datum.Evento,
                    description: "",
                    color: "#44CCFF",
                    type: ETipoTarea.ESPECIAL,
                    icon: "icons/activity/icon-evento.svg"
                })
            }

            _LoadCalendarEvent(dtStart, dtEnd, _OnItemParse, (_) => {
                let now = timeMonth.floor(new Date());
                let start = new Date(new Date(now).setMonth(now.getMonth() + this.indexMounth));
                let birthday = Array.from(_mapChilds.values())
                    .filter((d) => d.Nacimiento.getMonth() === start.getMonth())
                    .map((d) => {
                        let id = ETipoTarea.CUMPLE + "_" + d.IdChild;
                        let title = d.Nombre + " " + d.ApPaterno + " " + d.ApMaterno;

                        let date = timeDay(new Date(new Date().setDate(d.Nacimiento.getDate())));
                        let anios = date.getFullYear() - new Date().getFullYear();
                        let description = _L("agenda.cumple", anios);

                        return {
                            intId: d.IdChild,
                            id: id,
                            range: "", // "Todo el día",
                            start: date,
                            end: date,
                            title: title,
                            description: description,
                            color: "#3BCEAC",
                            type: ETipoTarea.CUMPLE,
                            icon: "icons/activity/icon-pastel.svg"
                        }
                    });

                data = data.concat(birthday);

                this.SetDataEvents(data);
            });
        });
    }

    private SetDataEvents(data: Array<IDatumActivity>) {
        data = data.filter((d) => timeDay.floor(new Date()).getTime() <= d.start.getTime());

        data.sort((a, b) => a.start.getTime() - b.start.getTime());
        let index = 0;
        let lastDay = 0;
        let result: Array<IDatumActivity> = [];
        data.forEach((item) => {
            index += 1;
            let day = item.start.getDate();
            if (lastDay != day) {
                let title = WEEKDAY[item.start.getDay()] + " " + item.start.getDate();
                // timeFormat("%a %d")(item.start);
                result.push({
                    intId: index,
                    id: index + "",
                    range: "",
                    start: new Date(),
                    end: new Date(),
                    title: title,
                    description: "",
                    color: "",
                    type: ETipoTarea.TITLE_DAY,
                    icon: ""
                });
                lastDay = day;
            }

            result.push(item);
        });

        this.listActivities = result;
        this.CreateListAgendas();
    }

    private CreateListAGendasContainer() {
        let listContainer = this.agendaContainer.classed("list-events", true)
            .classed("shadow-card", true);

        this.CreateHeaderAgendasContainer(listContainer);

        this.listAllEvents = listContainer.append("div").classed("list-all-events", true);

        this.eventsEmptyTag = new EmptyTag(this.listAllEvents.node())
            ._SetText(_L("agenda.agenda_without_records"))
            ._SetColor("var(--color-blue-action)")

        this.CreateListAgendas();

        this.CreateButtonAddAgendas(listContainer);
    }

    private CreateHeaderAgendasContainer(container: Selection<HTMLDivElement, any, any, any>) {
        this.headerList = container.append("div").classed("header-list", true);
        const self = this;
        const btnPrev = this.headerList.append("div")
            .classed("ic-prev", true)
            .style("display", "none")
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(ic_prev))
                    .style("height", "100%")
                    .style("width", "100%");
            })
            .on("click", function () {
                if (self.indexMounth === 0) return;
                self.indexMounth--;
                if (self.indexMounth === 0) btnPrev.style("display", "none");

                self.LoadActivities();
            })

        const txtMonthTitle = this.headerList.append("h2").classed("title", true);
        txtMonthTitle.text(timeFormat("%B %Y")(new Date()));

        this.headerList.append("div")
            .classed("ic-next", true)
            .call((btn) => {
                btn.append(() => _CreateElementFromHTML(ic_next))
                    .style("height", "100%")
                    .style("width", "100%");
            })
            .on("click", () => {
                this.indexMounth++;
                if (this.indexMounth > 0) btnPrev.style("display", "block");

                this.LoadActivities();
            });
    }

    private CreateListAgendas() {
        if (!this.listActivities.length) {
            this.eventsEmptyTag._ShowTag()
        } else {
            this.eventsEmptyTag._Remove()
        }

        const itemsAgenda = this.listAllEvents.selectAll<HTMLDivElement, any>(".item-agenda").data(this.listActivities);

        itemsAgenda?.exit().remove();
        itemsAgenda?.enter().append("div")
            .classed("item-agenda", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.append("div").classed("title-group", true).style("display", "none");

                const itemContainer = elemnt.append("div").classed("item-container", true);
                itemContainer.append("div").classed("img-container", true)//.attr("src", ic_item_agenda)
                const itemTexts = itemContainer.append("div");
                itemTexts.append("label").classed("title-item", true);
                itemTexts.append("label").classed("subtitle-item", true);

            })
            .merge(itemsAgenda)
            .on('click', (_, datum) => {
                if (datum.type != ETipoTarea.ACT) return;//Solo editar Actividades
                this.taskSelected = datum;

                this.ViewAddAgenda(true);
            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);
                //Comparar fecha
                if (datum.type == ETipoTarea.TITLE_DAY) {
                    elemnt.select(".title-group")
                        .style("display", "block")
                        .text(datum.title);

                    elemnt.select(".item-container").style("display", "none");
                    return;
                }

                elemnt.select(".title-group").style("display", "none");
                elemnt.select(".item-container").style("display", "flex")
                    .style("border-left-color", datum.color);

                ToImageSvgElement(datum.icon, elemnt.select(".img-container"), () => {
                    elemnt.select(".img-container").select("svg").style("fill", datum.color)
                });

                elemnt.select(".title-item").text(datum.title);
                elemnt.select(".subtitle-item").text(datum.range).classed("hide", !datum.range);
            });
    }

    private CreateButtonAddAgendas(container: Selection<HTMLDivElement, any, any, any>) {
        this.btnAddSave = container.append("button").classed("button-circle", true)
            .classed("add", true)
            .property("toSave", false)
            .on("click", () => {
                const toSave: boolean = this.btnAddSave.property("toSave");

                if (toSave) {
                    this.OnSaveButton();
                } else {
                    this.ViewAddAgenda(true);
                }
            })
            .html(ic_add);

        this.btnAddSave._SetMode = (mode) => {
            const toSave = mode == "save";
            this.btnAddSave.property("toSave", toSave)
                .html(toSave ? ic_done : ic_add);
        }
    }


    private CreateWeatherView() {
        const weathCnt = this.weatherContainer.classed("shadow-card", true);

        const descripWeath = weathCnt.append("div").classed("icon-description-weather", true);
        descripWeath.append("div").classed("icon-container", true)
            .classed("ic-type-weather", true)

        descripWeath.append("h1").classed("temptoday", true)//.text("Hoy °");
        descripWeath.append("h3").classed("temp-maxmin", true)//.text("min: ° / max: °");

        weathCnt.append("div").classed("weath-days", true);
    }

    private SetListWeathDays(container: Selection<HTMLDivElement, any, any, any>, listData: IWeather[]) {
        const itemsWDay = container.selectAll<HTMLDivElement, IWeather>(".w-day").data(listData);

        itemsWDay.exit().remove();
        itemsWDay.enter().append("div")
            .classed("w-day", true)
            .each((_, i, divs) => {
                const itemContainer = select(divs[i]);

                itemContainer.append("img")
                const itemTexts = itemContainer.append("div");
                itemTexts.append("h4").classed("item-day", true);
                itemTexts.append("h5").classed("item-max-min", true);

            })
            .merge(itemsWDay)
            .on('click', function (_, __) { })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);

                elemnt.select("img").attr("src", datum.iconV2 || 'icons/climate/' + datum.icon.replace("n", "d") + '.svg')

                let day = WEEKDAY[datum.date.getDay()];
                elemnt.select(".item-day").text(day);
                elemnt.select(".item-max-min").text(Math.floor(datum.min) + "° / " + Math.floor(datum.max) + "°");
            });
    }

    private ViewAddAgenda(toAdd: boolean) {
        this.listAllEvents?.selectAll("*").remove();

        if (toAdd) {
            this.headerList.select(".ic-next").style("display", "none");
            this.headerList.select(".ic-prev").style("display", 'none');
            this.AddFormInputs();

            this.headerList.insert("div", ".ic-prev")
                .classed("ic_back", true)
                .call((btn) => {
                    btn.append(() => _CreateElementFromHTML(ic_back))
                        .style("height", "100%")
                        .style("width", "100%");
                })
                .on("click", () => {
                    this.OnBackClick();
                });
        } else {
            this.headerList.select(".ic-next").style("display", "block");
            this.headerList.select(".ic-prev").style("display", this.indexMounth == 0 ? 'none' : 'block');
            this.CreateListAgendas();
        }

        this.btnAddSave._SetMode(toAdd ? "save" : "add");
    }


    private OnBackClick() {
        this.headerList.select(".ic_back").remove();
        this.taskSelected = null;

        this.ViewAddAgenda(false);
    }

    private AddFormInputs() {
        const elmInputs = this.listAllEvents.append("div")
            .classed("form-container", true);

        this.txtActivity = new Input(elmInputs, { inputType: 'text', placeholder: _L("agenda.activity"), className: 'input-name-agenda', required: true, maxLength: 300 });
        if (this.taskSelected) this.txtActivity.value = this.taskSelected.title;

        elmInputs.append("h4")
            .classed("lbl-day", true)
            .text(_L("general.day"));

        this.txtDate = elmInputs.append("input")
            .attr("type", "date")
            .property("value", _DateToInputFmt())
            .classed("input-date", true)


        if (this.taskSelected) {
            const dtTask = timeFormat("%Y-%m-%d")(this.taskSelected.start);
            this.txtDate.property("value", dtTask);
        }

        if (this.taskSelected) {
            elmInputs.append("button")
                .classed("button-circle", true)
                .classed("delete", true)
                .html(ic_trash)
                .on("click", () => {
                    if (this.taskSelected)
                        this.OnRemoveActivity(this.taskSelected.intId);
                })
        }
    }

    private UpdateWeather() {
        if (!this.weatherWeek) {
            this.weatherEmptyTag._ShowTag()
            // this.weatherContainer.remove() // TEMPORAL
            return;
        }
        this.weatherEmptyTag._Remove()

        const weatherToday = this.weatherWeek[0];

        const icImg = this.weatherContainer.select(".icon-container");
        if (weatherToday.iconV2) {
            icImg.append("img").attr("src", weatherToday.iconV2)
        } else {
            const urlImg = "icons/climate/" + weatherToday.icon.replace("n", "d") + ".svg";
            ToImageSvgElement(urlImg, icImg);
        }

        this.weatherContainer.select(".temptoday").text(Math.floor(weatherToday.day) + "°");
        this.weatherContainer.select(".temp-maxmin").text(`min: ${Math.floor(weatherToday.min)}° / max: ${Math.floor(weatherToday.max)}°`)

        let listDays = this.weatherWeek.filter((d) => d.id > 1 && d.id <= 6);
        const wDays = this.weatherContainer.select<HTMLDivElement>(".weath-days");
        this.SetListWeathDays(wDays, listDays);

    }

    private GetDateRange(): [Date, Date] {
        let now = timeMonth.floor(new Date());
        let start = new Date(new Date(now).setMonth(now.getMonth() + this.indexMounth));
        let end = new Date(new Date(start).setMonth(start.getMonth() + 1));
        end.setMilliseconds(end.getMilliseconds() - 1);

        return [start, end];
    }

    private OnSaveButton() {
        if (!this.txtActivity.Validate()) {
            this.txtActivity.FocusIn();
            return;
        }
        const title = this.txtActivity.value.trim();

        const dtSelected = this.txtDate.node()?.value;
        if (!dtSelected) {
            ShowToast(_L("agenda.add_act"), _L("agenda.fecha_invalid"), 'info');
            return;
        }

        const dtDay = new Date(dtSelected);
        dtDay.setMinutes(dtDay.getMinutes() + dtDay.getTimezoneOffset());//Fecha Local
        const now = timeDay.floor(new Date());

        if (dtDay.getTime() < now.getTime()) {
            ShowToast(_L("agenda.add_act"), _L("agenda.fecha_nomenor"), 'info');
            return;
        }


        let dtEnd: Date = new Date(dtDay.getTime());
        dtEnd.setDate(dtEnd.getDate() + 1);
        dtEnd.setMilliseconds(dtEnd.getMilliseconds() - 1);

        let task = <ITaskAgenda>{};
        task.IdTarea = 0;
        task.Inicio = dtDay;
        task.Fin = dtEnd;
        task.Titulo = title;
        task.Descripcion = "";

        LOADING.Show();

        if (this.taskSelected != null) {
            _UpdateTaskAgenda(this.taskSelected.intId, title, dtDay, dtEnd, (resultado) => this.OnResponseCompletion(resultado, "update"));
        } else {
            _AddTaskAgenda(title, dtDay, dtEnd, (resultado) => {
                if (resultado > 0) task.IdTarea = resultado;
                this.OnResponseCompletion(resultado, "record");
            });
        }
    }

    private OnResponseCompletion(successCode: number, action: "record" | "update" | "delete") {
        // const descr = title.toLowerCase().substring(0, title.length - 1) + "do";

        if (successCode > 0) {
            const [isInsert, isUpdate] = [action == "record", action == "update"];
            const title = _L(isInsert ? "agenda.add_act" : isUpdate ? "agenda.upd_act" : "agenda.del_act");
            const httpMessage = _HttpMsg(isInsert ? "Agenda/NuevaTareaAgenda" : isUpdate ? "Agenda/ActualizarTareaAgenda" : "Agenda/EliminarTareaAgenda", successCode, action);
            ShowToast(title, httpMessage, 'success');

            this.OnBackClick();
            this.LoadActivities();
        } else {
            ShowToast(_L("agenda.add_act"), _L("general.error") + ", " + _L("general.user_reintent").toLowerCase(), "error");
        }

        LOADING.Dismiss();
    }

    private OnRemoveActivity(idTask: number) {
        new ConfirmDialog()
            .SetDescription(_L("agenda.confirm_delete"))
            .SetOnConfirm(() => {
                LOADING.Show();

                _RemoveTaskAgenda(idTask, (STATUS) => this.OnResponseCompletion(STATUS, "delete"));
            });
    }
}